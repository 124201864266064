import React from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";

// Brand icons: removed as they were only used in storybook
import { logError } from "Utils/logger";

import { Span } from "Common/HTML";
import duotone from "Common/Icon/FontAwesome/duotone";
import light from "Common/Icon/FontAwesome/light";
import regular from "Common/Icon/FontAwesome/regular";
import solid from "Common/Icon/FontAwesome/solid";

/** Map icon sets to icon names */
const iconMapping = {
  /** @deprecated Previously only used in tests & contributed to large prod bundles */
  brands: {},
  duotone,
  light,
  solid,
  regular,
};
library.add(
  ...Object.values(iconMapping.duotone),
  ...Object.values(iconMapping.light),
  ...Object.values(iconMapping.solid),
  ...Object.values(iconMapping.regular),
);
const FALLBACK_ICON = iconMapping.regular["question-circle"];
export type IconProps = {
  icon: string;
  bordered?: boolean;
  fixedWidth?: boolean;
  flavor: "regular" | "solid" | "light" | "duotone" | "brands";
  spacing: "none" | "left" | "right" | "both";
  spin?: boolean | "pulse";
  size?: "xs" | "sm" | "large" | "lg" | "2x" | "3x" | "4x" | "5x" | "6x" | "7x" | "8x" | "9x" | "10x";
  float?: "left" | "right" | "none";
  flip?: string | "horizontal" | "vertical" | "both";
  swapOpacity?: boolean;

  /**
  @ignore
  * */
  className?: string;
};
export class UndecoratedIcon extends React.Component<IconProps> {
  static defaultProps = {
    spacing: "none",
    flavor: "regular",
    countLocation: "top-right",
  };

  render() {
    let { icon, bordered, fixedWidth, flavor, spacing, spin, size, float, flip, count, countLocation, className, ...other } = this.props;
    const prefix = `fa${flavor[0]}`;
    const spanSpacing = spacing !== "none" ? `Icon--spacing-${spacing}` : "";
    const countClasses = count ? "fa-layers fa-fw" : "";
    const faSize = size === "large" ? "fa-lg" : size ? `fa-${size}` : "";
    const outerSpanClassName = classnames(spanSpacing, countClasses, faSize);
    const faCountLocation = `fa-layers-${countLocation}`;
    className = classnames(className, float === "left" ? "fa-pull-left" : float === "right" ? "fa-pull-right" : "", {
      "fa-fw": fixedWidth,
      "fa-pulse": spin === "pulse",
      "fa-spin": spin && spin !== "pulse",
      "fa-border": bordered,
    });
    const optional = {};

    if (flip) {
      optional.flip = flip;
    }

    let mappedIcon = iconMapping[flavor]?.[icon];

    // Missing icon in prod? Log w/ honeybadger and use (?) as fallback
    if (!mappedIcon) {
      logError(
        `Icon not found: "${icon}". A fallback will be shown. To fix, add the icon to https://github.com/SimplyInsured/site/tree/main/client/src/Common/Icon\n`,
        {
          name: "Icon",
          component: icon,
          action: "iconMapping",
        },
        {
          icon,
          flavor,
          className,
        },
      );
      mappedIcon = FALLBACK_ICON;
    }

    return (
      <Span className={outerSpanClassName} {...other}>
        <FontAwesomeIcon icon={mappedIcon} {...optional} className={className} />
        {count && (
          <Span
            className={classnames("fa-layers-counter", faCountLocation, "fa-2x")}
            style={{
              background: "Tomato",
            }}
          >
            {count}
          </Span>
        )}
      </Span>
    );
  }
}
export default UndecoratedIcon;
