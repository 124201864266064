import { faAngleDoubleRight } from "@fortawesome/pro-duotone-svg-icons/faAngleDoubleRight";
import { faAngleDown } from "@fortawesome/pro-duotone-svg-icons/faAngleDown";
import { faAngleLeft } from "@fortawesome/pro-duotone-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/pro-duotone-svg-icons/faAngleRight";
import { faAngleUp } from "@fortawesome/pro-duotone-svg-icons/faAngleUp";
import { faBabyCarriage } from "@fortawesome/pro-duotone-svg-icons/faBabyCarriage";
import { faBan } from "@fortawesome/pro-duotone-svg-icons/faBan";
import { faBuilding } from "@fortawesome/pro-duotone-svg-icons/faBuilding";
import { faCalendarEdit } from "@fortawesome/pro-duotone-svg-icons/faCalendarEdit";
import { faCar } from "@fortawesome/pro-duotone-svg-icons/faCar";
import { faCaretDown } from "@fortawesome/pro-duotone-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/pro-duotone-svg-icons/faCaretUp";
import { faCheck } from "@fortawesome/pro-duotone-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import { faCheckDouble } from "@fortawesome/pro-duotone-svg-icons/faCheckDouble";
import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/pro-duotone-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons/faChevronRight";
import { faChevronUp } from "@fortawesome/pro-duotone-svg-icons/faChevronUp";
import { faChild } from "@fortawesome/pro-duotone-svg-icons/faChild";
import { faCircle } from "@fortawesome/pro-duotone-svg-icons/faCircle";
import { faCity } from "@fortawesome/pro-duotone-svg-icons/faCity";
import { faClipboardPrescription } from "@fortawesome/pro-duotone-svg-icons/faClipboardPrescription";
import { faCog } from "@fortawesome/pro-duotone-svg-icons/faCog";
import { faComments } from "@fortawesome/pro-duotone-svg-icons/faComments";
import { faCreditCard } from "@fortawesome/pro-duotone-svg-icons/faCreditCard";
import { faDollarSign } from "@fortawesome/pro-duotone-svg-icons/faDollarSign";
import { faDownload } from "@fortawesome/pro-duotone-svg-icons/faDownload";
import { faEdit } from "@fortawesome/pro-duotone-svg-icons/faEdit";
import { faEllipsisV } from "@fortawesome/pro-duotone-svg-icons/faEllipsisV";
import { faEnvelope } from "@fortawesome/pro-duotone-svg-icons/faEnvelope";
import { faExclamationCircle } from "@fortawesome/pro-duotone-svg-icons/faExclamationCircle";
import { faExclamationTriangle } from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";
import { faExternalLink } from "@fortawesome/pro-duotone-svg-icons/faExternalLink";
import { faExternalLinkAlt } from "@fortawesome/pro-duotone-svg-icons/faExternalLinkAlt";
import { faEye } from "@fortawesome/pro-duotone-svg-icons/faEye";
import { faFileAlt } from "@fortawesome/pro-duotone-svg-icons/faFileAlt";
import { faFileEdit } from "@fortawesome/pro-duotone-svg-icons/faFileEdit";
import { faFileInvoiceDollar } from "@fortawesome/pro-duotone-svg-icons/faFileInvoiceDollar";
import { faFilePlus } from "@fortawesome/pro-duotone-svg-icons/faFilePlus";
import { faFolderOpen } from "@fortawesome/pro-duotone-svg-icons/faFolderOpen";
import { faGlasses } from "@fortawesome/pro-duotone-svg-icons/faGlasses";
import { faGrin } from "@fortawesome/pro-duotone-svg-icons/faGrin";
import { faGrinHearts } from "@fortawesome/pro-duotone-svg-icons/faGrinHearts";
import { faHandHoldingHeart } from "@fortawesome/pro-duotone-svg-icons/faHandHoldingHeart";
import { faHeadset } from "@fortawesome/pro-duotone-svg-icons/faHeadset";
import { faHeart } from "@fortawesome/pro-duotone-svg-icons/faHeart";
import { faHospitalUser } from "@fortawesome/pro-duotone-svg-icons/faHospitalUser";
import { faInfoCircle } from "@fortawesome/pro-duotone-svg-icons/faInfoCircle";
import { faLifeRing } from "@fortawesome/pro-duotone-svg-icons/faLifeRing";
import { faLocation } from "@fortawesome/pro-duotone-svg-icons/faLocation";
import { faLock } from "@fortawesome/pro-duotone-svg-icons/faLock";
import { faLongArrowAltRight } from "@fortawesome/pro-duotone-svg-icons/faLongArrowAltRight";
import { faMagic } from "@fortawesome/pro-duotone-svg-icons/faMagic";
import { faMedkit } from "@fortawesome/pro-duotone-svg-icons/faMedkit";
import { faMeh } from "@fortawesome/pro-duotone-svg-icons/faMeh";
import { faMinusCircle } from "@fortawesome/pro-duotone-svg-icons/faMinusCircle";
import { faMoneyBill } from "@fortawesome/pro-duotone-svg-icons/faMoneyBill";
import { faMoneyCheckAlt } from "@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt";
import { faMountains } from "@fortawesome/pro-duotone-svg-icons/faMountains";
import { faPencilAlt } from "@fortawesome/pro-duotone-svg-icons/faPencilAlt";
import { faPhone } from "@fortawesome/pro-duotone-svg-icons/faPhone";
import { faPlane } from "@fortawesome/pro-duotone-svg-icons/faPlane";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/pro-duotone-svg-icons/faPlusCircle";
import { faPrescriptionBottleAlt } from "@fortawesome/pro-duotone-svg-icons/faPrescriptionBottleAlt";
import { faPrint } from "@fortawesome/pro-duotone-svg-icons/faPrint";
import { faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons/faQuestionCircle";
import { faRepeat } from "@fortawesome/pro-duotone-svg-icons/faRepeat";
import { faRunning } from "@fortawesome/pro-duotone-svg-icons/faRunning";
import { faSearch } from "@fortawesome/pro-duotone-svg-icons/faSearch";
import { faShoppingCart } from "@fortawesome/pro-duotone-svg-icons/faShoppingCart";
import { faSignOutAlt } from "@fortawesome/pro-duotone-svg-icons/faSignOutAlt";
import { faSmile } from "@fortawesome/pro-duotone-svg-icons/faSmile";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { faStar } from "@fortawesome/pro-duotone-svg-icons/faStar";
import { faStarHalfAlt } from "@fortawesome/pro-duotone-svg-icons/faStarHalfAlt";
import { faStethoscope } from "@fortawesome/pro-duotone-svg-icons/faStethoscope";
import { faSync } from "@fortawesome/pro-duotone-svg-icons/faSync";
import { faSyncAlt } from "@fortawesome/pro-duotone-svg-icons/faSyncAlt";
import { faTh } from "@fortawesome/pro-duotone-svg-icons/faTh";
import { faTimes } from "@fortawesome/pro-duotone-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/pro-duotone-svg-icons/faTimesCircle";
import { faTooth } from "@fortawesome/pro-duotone-svg-icons/faTooth";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { faTrophy } from "@fortawesome/pro-duotone-svg-icons/faTrophy";
import { faUniversity } from "@fortawesome/pro-duotone-svg-icons/faUniversity";
import { faUnlock } from "@fortawesome/pro-duotone-svg-icons/faUnlock";
import { faUpload } from "@fortawesome/pro-duotone-svg-icons/faUpload";
import { faUser } from "@fortawesome/pro-duotone-svg-icons/faUser";
import { faUserInjured } from "@fortawesome/pro-duotone-svg-icons/faUserInjured";
import { faUserMd } from "@fortawesome/pro-duotone-svg-icons/faUserMd";
import { faUserPlus } from "@fortawesome/pro-duotone-svg-icons/faUserPlus";
import { faUsers } from "@fortawesome/pro-duotone-svg-icons/faUsers";
import { faWheelchair } from "@fortawesome/pro-duotone-svg-icons/faWheelchair";
import { faWrench } from "@fortawesome/pro-duotone-svg-icons/faWrench";

export default {
  "angle-double-right": faAngleDoubleRight,
  "angle-down": faAngleDown,
  "angle-left": faAngleLeft,
  "angle-right": faAngleRight,
  "angle-up": faAngleUp,
  "baby-carriage": faBabyCarriage,
  "caret-down": faCaretDown,
  "caret-up": faCaretUp,
  "check-circle": faCheckCircle,
  "chevron-down": faChevronDown,
  "chevron-left": faChevronLeft,
  "chevron-right": faChevronRight,
  "chevron-up": faChevronUp,
  "circle-xmark": faTimesCircle,
  "clipboard-prescription": faClipboardPrescription,
  "dollar-sign": faDollarSign,
  "ellipsis-v": faEllipsisV,
  "exclamation-circle": faExclamationCircle,
  "exclamation-triangle": faExclamationTriangle,
  "external-link-alt": faExternalLinkAlt,
  "external-link": faExternalLink,
  // multiple names: external-link === arrow-up-right-from-square
  "file-alt": faFileAlt,
  "file-edit": faFileEdit,
  "file-invoice-dollar": faFileInvoiceDollar,
  "file-plus": faFilePlus,
  "hand-holding-heart": faHandHoldingHeart,
  "hospital-user": faHospitalUser,
  "info-circle": faInfoCircle,
  "life-ring": faLifeRing,
  // multiple names: life-saver === life-ring
  "life-saver": faLifeRing,
  // multiple names: life-saver === life-ring
  "long-arrow-alt-right": faLongArrowAltRight,
  "minus-circle": faMinusCircle,
  "money-bill": faMoneyBill,
  "money-check-alt": faMoneyCheckAlt,
  mountains: faMountains,
  "pencil-alt": faPencilAlt,
  "plus-circle": faPlusCircle,
  "prescription-bottle-alt": faPrescriptionBottleAlt,
  "question-circle": faQuestionCircle,
  "shopping-cart": faShoppingCart,
  "sign-out-alt": faSignOutAlt,
  "star-half-alt": faStarHalfAlt,
  "sync-alt": faSyncAlt,
  // refresh === sync-alt
  th: faTh,
  "times-circle": faTimesCircle,
  "user-injured": faUserInjured,
  "user-md": faUserMd,
  "user-plus": faUserPlus,
  ban: faBan,
  building: faBuilding,
  car: faCar,
  check: faCheck,
  child: faChild,
  circle: faCircle,
  city: faCity,
  cog: faCog,
  comments: faComments,
  download: faDownload,
  edit: faEdit,
  envelope: faEnvelope,
  grin: faGrin,
  grinHearts: faGrinHearts,
  headset: faHeadset,
  heart: faHeart,
  location: faLocation,
  lock: faLock,
  magic: faMagic,
  medkit: faMedkit,
  meh: faMeh,
  phone: faPhone,
  plane: faPlane,
  plus: faPlus,
  print: faPrint,
  refresh: faSyncAlt,
  // refresh === sync-alt
  running: faRunning,
  search: faSearch,
  smile: faSmile,
  spinner: faSpinner,
  star: faStar,
  stethoscope: faStethoscope,
  sync: faSync,
  times: faTimes,
  trash: faTrash,
  trophy: faTrophy,
  university: faUniversity,
  unlock: faUnlock,
  upload: faUpload,
  user: faUser,
  users: faUsers,
  wheelchair: faWheelchair,
  repeat: faRepeat,
  glasses: faGlasses,
  tooth: faTooth,
  eye: faEye,
  "credit-card": faCreditCard,
  "folder-open": faFolderOpen,
  "calendar-edit": faCalendarEdit,
  "check-double": faCheckDouble,
  wrench: faWrench,
};
