// This module is responsible for storing the logged-in user account in session
// storage, or fetching it from the server if it isn't available. We use
// localStorage for this.
import mitt from "mitt";

import { appendAuthParams } from "Common/TokenAuth/Utils";

type AuthResponse = {
  csrfToken: {
    readonly name: string;
    readonly value: string;
  };
  account:
    | {
        readonly id: string;
        readonly email: string;
        readonly name: string;
      }
    | null
    | undefined;
};

// const LOCALSTORAGE_KEY = "authResponse";
export const CSRF_BUSTER = "while(1);";

export const authResponse = {
  // This is the most recently received AuthResponse.
  currentValue: null as AuthResponse | null | undefined,
  // This promise is always resolved with the most recently received
  // AuthResponse.
  promise: null as any as Promise<AuthResponse>,
  // To listen for ongoing changes, use 'on'/'off' with 'change' to
  // subscribe/unsubscribe from this event emitter.
  events: mitt(),

  // When we determine that the AuthResponse is invalid, use this to trigger a
  // refetch.
  invalidate(): Promise<AuthResponse> {
    this.currentValue = null;
    // Clear out the meta tags
    handleAuthResponseChange();
    // localStorageCache.clearInPageCache();
    loadAuthResponse();
    return this.promise;
  },
};

// The initial value of the promise simply listens for the first change event
// and resolves itself. Future updates will just replace the promise with an
// already-resolved new one.
const createPendingAuthResponsePromise = () => {
  authResponse.promise = new Promise((resolve) => {
    const handler = (response) => {
      resolve(response as any as AuthResponse);
      authResponse.events.off("change", handler);
    };

    authResponse.events.on("change", handler);
  });
};

// Parse an authentication token if possible.

/* const parseAuthResponseJson = (json: string): ?AuthResponse => {
  let response;
  try {
    response = JSON.parse(json);
  } catch (e) {
    // Do nothing
  }
  if (!response || typeof response.csrfToken !== "object") {
    return null;
  }
  return response;
}; */

// Disabled. See SI-3151.
/* const localStorageCache = {
  // LocalStorage tracks strings only, so we store the literal string when we get
  // it to avoid duplicated updates of the value.
  cachedJson: (null: ?string),
  cachedValue: (null: AuthResponse | false | null),

  // Returns a valid AuthResponse or throws.
  get(): AuthResponse {
    if (this.cachedValue) return this.cachedValue;
    if (this.cachedValue === false) {
      throw new Error("Cache disabled for server fetch");
    }
    if (!window.localStorage) {
      throw new Error("localStorage not available");
    }
    const responseJson = window.localStorage.getItem(LOCALSTORAGE_KEY);
    this.cachedJson = responseJson;
    this.cachedValue = null;
    const response = parseAuthResponseJson(responseJson);
    if (!response) {
      throw new Error("No valid authResponse in localStorage");
    }
    this.cachedValue = response;
    return response;
  },

  // Modify the value in the cache and update localStorage
  set(value: AuthResponse) {
    if (value === this.cachedValue) return;
    this.cachedValue = value;
    this.cachedJson = JSON.stringify(value);
    if (!window.localStorage) return;
    window.localStorage.setItem(LOCALSTORAGE_KEY, this.cachedJson);
  },

  // When another tab updates the stored AuthResponse (e.g. by logging in to the
  // web site), we need to have that information be reflected in this tab. This
  // will return true if the AuthResponse has changed.
  handleStorageEvent(e: StorageEvent): boolean {
    if (e.key === LOCALSTORAGE_KEY && e.newValue !== this.cachedJson) {
      this.cachedValue = null;
      try {
        this.get();
      } catch (e) {
        // The new response is invalid, but don't throw here.
      }
      return true;
    }
    return false;
  },

  // Invalidate the in-page cache, but don't actually clear localStorage. This
  // is used when we are about to do a server fetch.
  clearInPageCache() {
    this.cachedValue = false;
  },
}; */

// Fetch an authentication response from the server directly.
// TODO - replace csrf-meta with a dedicated zero endpoint
const fetchFromServer = (): Promise<AuthResponse> =>
  fetch(appendAuthParams("/csrf-meta.json"), {
    credentials: "same-origin",
  })
    .then((response) => {
      if (!response.ok) {
        return Promise.reject(new Error(`Unable to authenticate: ${response.status} ${response.statusText}`));
      }
      return response.text();
    })
    .then((responseText) => {
      // Strip the CSRF buster from the response and parse
      const parsed = JSON.parse(responseText.slice(CSRF_BUSTER.length));
      const csrfToken = {
        name: parsed.param,
        value: parsed.token,
      };

      const { account } = parsed;
      return {
        csrfToken,
        account,
      };
    });

// Fetch an authentication response from the meta tag. Note this will never
// detect a logged-in response, so it should be used in conjunction with a real
// server fetch. It is only useful outside of a ZeroRuby context, for example
// when using ReactOnRails.
const fetchFromMetaTag = (): AuthResponse | null | undefined => {
  const paramTag: HTMLMetaElement = document.querySelector(`meta[name=csrf-param]`) as any;

  const tokenTag: HTMLMetaElement = document.querySelector(`meta[name=csrf-token]`) as any;

  if (paramTag && paramTag.content.length > 0 && tokenTag && tokenTag.content.length > 0) {
    return {
      csrfToken: {
        name: paramTag.content,
        value: tokenTag.content,
      },
      account: null,
    };
  }
  return null;
};

// Given a valid AuthResponse, set all of the in-page details to use it.
const handleAuthResponseChange = (): void => {
  // Remove all previous CSRF token metas
  const collection = document.getElementsByTagName("meta");

  for (let i = 0; i < collection.length; ) {
    const meta = collection.item(i);

    if (meta && (meta.name === "csrf-param" || meta.name === "csrf-token")) {
      (meta.parentElement as any).removeChild(meta);
    } else {
      i++;
    }
  }

  // Make the promise be pending
  createPendingAuthResponsePromise();

  if (!authResponse.currentValue) return;
  const { currentValue } = authResponse;
  authResponse.events.emit("change", currentValue);

  // Set up the new meta tokens
  const metaParam = document.createElement("meta");
  metaParam.name = "csrf-param";
  metaParam.content = currentValue.csrfToken.name;

  const metaToken = document.createElement("meta");
  metaToken.name = "csrf-token";
  metaToken.content = currentValue.csrfToken.value;

  document.getElementsByTagName("head")[0].appendChild(metaParam);
  document.getElementsByTagName("head")[0].appendChild(metaToken);
};

// Perform the initial load of the AuthResponse
const loadAuthResponse = () => {
  let response = fetchFromMetaTag();
  if (!response) {
    /* try {
      response = localStorageCache.get();
    } catch (_) { */
    response = fetchFromServer(); // }
  }
  Promise.resolve(response).then((response: AuthResponse) => {
    authResponse.currentValue = response;
    // localStorageCache.set(response);
    handleAuthResponseChange();
  });
};

// Monitor for changes from other tabs
/* const onStorageEvent = (e: StorageEvent) => {
  if (localStorageCache.handleStorageEvent(e)) {
    authResponse.currentValue = localStorageCache.get();
    handleAuthResponseChange();
  }
}; */

createPendingAuthResponsePromise();
loadAuthResponse();
/* window.addEventListener("storage", onStorageEvent);

if (module.hot) {
  (module: any).hot.dispose(_ => {
    // Clean up event listeners when hot reloaded
    window.removeEventListener("storage", onStorageEvent);
  });
} */
