import installHoneybadger from "ZeroRuby/snippets/honeybadger";

const honeybadger = installHoneybadger();

/**
 * Notification options on Honeybagder, helps with aggregating issues
 * @see https://docs.honeybadger.io/lib/javascript/guides/reporting-errors/#code-honeybadger-notify-code-return-value
 * */
export interface LoggerArgs {
  /** Descriptive name of the error type (usually inferred) */
  name?: string;
  /** Section/controller in question */
  component: string;
  /** Action that triggered the arg */
  action: string;
  /** Explicit message, if not inferred from error */
  message?: string;
}

export interface LoggerContext {
  /** Typically set by middleware or the install script */
  account?: string;
  [key: string]: any;
}

/**
 * Logs an error message and optionally sends it to Honeybadger for error tracking.
 *
 * @param {Error | string} err  - The error to log, which can be an Error object or a string.
 * @param {Object} args         - Honeybadger's notification args
 * @param {Object} context      - Additional context information to include with the error.
 */
export const logError = (error: Error | string, args: LoggerArgs, context: LoggerContext = {}) => {
  let message = "";

  if (error instanceof Error) {
    message = error.message;
  } else if (typeof error === "string") {
    message = error;
  } else {
    // shouldn't hit this with type safety, but possible in untyped code paths
    message = (error as any)?.toString();
  }

  try {
    const honeybadgerArgs = {
      ...args,
      context,
    };
    honeybadger.notify(error, honeybadgerArgs);
  } catch (honeybadgerErr) {
    console.error("Error (unable to report to Honeybadger):", message, {
      error,
      honeybadgerErr,
    });
  } finally {
    console.error("Error:", message, error);
  }
};
